<template>
  <div v-if="configurations.length>0">
    <field-manager></field-manager>
  </div>
</template>

<script>
import { mapState} from "vuex";
import FieldManagerVue from '../components/admin/FieldManager.vue';

export default {
  name: "Holidays",
  components: {
    'field-manager': FieldManagerVue
  },
  async beforeCreate() {
    await this.$store.dispatch("configuration/retriveConfigurations");
  },

  data(){
    return {
    };
  },
  computed: {
    ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration'
    })
  }
}
</script>

<style scoped>

</style>