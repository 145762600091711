<template>
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
   <div class=" my-border">
     <v-card-title class="py-0 my-0 customlightgray">
       <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{ $t("Manage Fields") }}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                        class="custom-color-accent pa-1">{{fields.length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search.searchWord"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed @click="addItem" v-if="$checkPermission('AdminMenu.Read')">{{$t("newfield")}}</v-btn>
            </v-col>
            
        </v-row>
      </v-card-title>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t(formTitle)}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-form  v-on:submit.prevent ref="form"
              v-model="valid"
              lazy-validation >
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="requestConfiguration.LookUpStringE" :rules="[rules.required]" outlined dense :label="$t('Field string in English')" :placeholder="$t('Field string in English')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="requestConfiguration.LookUpStringF" :rules="[rules.required]" outlined dense :label="$t('Field string in French')" :placeholder="$t('Field string in French')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="requestConfiguration.LookUpKey" :rules="[rules.required]" outlined dense :label="$t('fieldLookup')" :placeholder="$t('fieldLookup')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="requestConfiguration.LookUpOther" :rules="[rules.required]" outlined dense :label="$t('fieldKey')" :placeholder="$t('fieldKey')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                        v-model="requestConfiguration.LookUpInt"
                        :items="fieldTypes()"
                        item-text="text"
                        item-value="value"
                        outlined
                        dense
                        :label="$t('fieldType')"
                        :placeholder="$t('select')"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                        v-model="requestConfiguration.LookUpFlag"
                        :items="modules"
                        item-text="text"
                        item-value="value"
                        outlined
                        small-chips
                        dense
                        :label="$t('Module')"
                        :placeholder="$t('select')"
                    >
                     <template v-slot:selection="data">
                          <v-chip :input-value="data.selected" class="custom-color-accent" small>
                            <strong>{{$t( data.item.text )}}</strong>
                          </v-chip>
                      </template>
                     <template v-slot:item="data">
                          <span :input-value="data.item">
                            {{$t( data.item.text )}}
                          </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="close = dialog = false">{{$t('cancel')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="save">{{$t('save')}}</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title>{{$t('delete')}}</v-card-title>
          <v-card-text>{{$t('Are you sure you want to delete this configuration?')}}</v-card-text>
          <v-card-actions>
            <v-btn class="custom-color-accent-text" text @click="dialogDelete = false">{{$t('Close')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="deleteConfig()">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-divider></v-divider>
      <v-data-table :mobile-breakpoint="1081" 
          v-if="configurations"
          :headers="headers"
          :items="fields"
          sort-by="id"
          class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
          :search="search.searchWord"
          :loading="loading" 
          :items-per-page="15" 
          :loading-text="$t('Loading')"
          :footer-props="{ 'items-per-page-text': $t('rowsperpage') }"
        >
        <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
        <template class="pa-0 ma-0" v-slot:[`item.LookUpInt`]="{ item }">
          {{ fieldTypes().filter(i=>i.value==item.LookUpInt)[0].text }}
        </template>
        <template class="pa-0 ma-0" v-slot:[`item.LookUpFlag`]="{ item }">
          {{ $t(modules.filter(i=>i.value==item.LookUpFlag)[0].text) }}
        </template>
        <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
          <v-row class="ma-0 pa-0 text-right">
            <v-col cols="12" class="ma-0 pa-0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("edit")}}</span>
              </v-tooltip>
               <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("delete")}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import moment from "moment";
import { MODULES } from "@/helpers/exports";
export default {
  name: "Fields",
  data(){
    return {
      valid: true,
      rules: {
          required: value => !!value || this.$t('required'),
      },
      LookUpName:'',
      editFlag: false,
      deletedItem:"",
      modules: MODULES,
      editedIndex: 0,
      headers: [
        {
          text:  this.$t("ID"),
          align: "left",
          sortable: true,
          value: "LookUpTableId"
        },
        { text: this.$t("nameinenglish"), value: "LookUpStringE"},
        { text: this.$t("nameinfrench"), value: "LookUpStringF"},
        { text: this.$t("fieldLookup"), value: "LookUpKey" },
        { text: this.$t("fieldKey"), value: "LookUpOther" },
        { text: this.$t("fieldType"), value: "LookUpInt" },
        { text: this.$t("Module"), value: "LookUpFlag" },
        { text: "", value: "action", sortable: false }
      ],
      dialog: false,
      dialogDelete: false,
      fields: [],
      search: {
        searchword:'',
      },
    }
  },
  computed: {
    ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration',
      formTitle() {
        return this.editedIndex === 0 ? "newfield" : "editfield";
      }
    })
  },
  async mounted(){
    await this.getFields();
  },
  watch: {
  },
  methods:{
    addItem(){
      this.dialog = true;
      this.editedIndex = 0;
      this.editFlag = false;
      this.emptyConfiguration();
    },
    fieldTypes(){
      return [
        {value: 0, text: this.$t('Dropdown Field')},
        {value: 1, text: this.$t('Text Field')},
        {value: 2, text: this.$t('Date Field')},
        {value: 3, text: this.$t('Dynamic Multiselect Field')},
        {value: 4, text: this.$t('Static Multiselect Field')},
      ]
    },
    ...mapActions('configuration',['addConfiguration', 'updateConfiguration','setConfigurationItem','setFlagEdit', 'removeConfiguration', 'emptyConfiguration']),
    formatDate(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    getFields(){
      this.fields=this.configurations.filter( filter => filter.LookUpName === 'FManager' )
    },
    save(){
      this.requestConfiguration.LookUpName = 'FManager';
      if(this.editFlag){
         if(this.$refs.form.validate()){
          this.updateConfiguration(this.requestConfiguration).then(()=>{
            this.getFields();
            let color="dark";
            let alert="itemUpdatedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
            this.editFlag = false;
          })
        }
        else
          return
      }else{
        if(this.$refs.form.validate()){
          this.addConfiguration(this.requestConfiguration).then(()=>{
            this.getFields();
            let color="success";
            let alert="itemAddedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
          })
          
        }
        else
          return
      }
      this.dialog = false;
    },
    editItem(item){
        this.dialog = true;
        this.editedIndex = -1;
        this.editFlag = true;
        this.setConfigurationItem(item);
    },
    deleteItem(item){
        this.deletedItem = item;
        this.dialogDelete = true;
    },
    async deleteConfig(){
      this.removeConfiguration( this.deletedItem.LookUpTableId ).then(()=>{
      this.dialogDelete = false;
          this.getFields();
          let color="dark";
          let alert="itemRemovedAlert";
          this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
      })
    },
    close() {
      this.loading = false;
    },
  }

}
</script>

<style scoped>

</style>